import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SubForm from "../components/subform";

const getCategory = (items) => {
  let tempItems = items.map((items) => {
    return items.node.category;
  });
  let tempCategories = new Set(tempItems);
  let categories = Array.from(tempCategories);
  categories = ["All", ...categories];
  return categories;
};

export default class Products extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;

    this.state = {
      message: data.contentfulAboutSection.message,
      items: data.allContentfulProducts.edges,
      filterItems: data.allContentfulProducts.edges,
      categories: getCategory(data.allContentfulProducts.edges),
    };
  }

  handleItems = (category) => {
    let tempItems = [...this.state.items];
    if (category === "All") {
      this.setState(() => {
        return { filterItems: tempItems };
      });
    } else {
      let items = tempItems.filter(({ node }) => node.category === category);
      this.setState(() => {
        return { filterItems: items };
      });
    }
  };

  render() {
    return (
      <Layout>
        <SEO
          title="Products"
          keywords={[
            `WTW `,
            `WTW Analytics`,
            `wtwanalytics.de`,
            `wtwanalytics`,
            `WTW de`,
            `Wastewater treatment`,
            `systems lab`,
            `water analysis`,
            ` quality online measurement technology`,
            `water analysis WTW`,
            `Lab laboratory`,
            `lab`,
            `On-Line`,
            `Online Process`,
            `IDS Wireless`,
            `IQSN`,
            `IQ SENSOR NET`,
            `Photometer`,
            `OxiTop`,
            `MultiLine`,
            `inoLab`,
            `photoLab `,
          ]}
        />
        <div className="site-container section" id="Products">
          <div className="container">
            <div className="section-head">
              <h1 className="line-heading h2">Products</h1>
            </div>
            <div className="alert alert-danger text-center ">
              {this.state.message}
            </div>
            <ul
              className="category list-inline mb-5"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {this.state.categories.map((category, index) => {
                return (
                  <li className="list-inline-item ">
                    <button
                      type="button"
                      key={index}
                      className="btn btn-primary text-capitalized mx-2"
                      onClick={() => {
                        this.handleItems(category);
                      }}
                    >
                      {category}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="row products-container">
              {this.state.filterItems.map(({ node }) => {
                return (
                  <div className="col-lg-3 product" key={node.id}>
                    <Link to={`/` + node.slug}>
                      <Img
                        fluid={node.p1FeatureImage.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    </Link>
                    <Link className="  mt-2 mb-0" to={`/` + node.slug}>
                      <h3>{node.title}</h3>
                    </Link>
                    <div className=" ">
                      <p className="category text-muted my-1">
                        &rsaquo; {node.category}
                      </p>
                      <p className="lead">{node.excerpt}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <SubForm />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ProductsQuery {
    allContentfulProducts(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          product2
          slug
          p2FeatureImage {
            fluid(maxWidth: 1500) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          category
          excerpt
          p1FeatureImage {
            fluid(maxWidth: 1500) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          createdAt
        }
      }
    }
    contentfulAboutSection {
      message
    }
  }
`;
